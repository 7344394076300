.loader-container {
    display: flex;
  }
  
  .loader {
    border-color: #44b1fa;
    border-top: 8px solid #f9f9f9;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  