

.logo{
  height: 20px;
}
.ag-root-wrapper{
  border: none !important;
  border-radius: 0 !important;
}
.leftNav li a{
  padding: 6px 2px;
}
.leftNav li a:hover {
  color: white;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity));
    padding: 6px 2px;
    border-radius: 4px;
}
.leftNav .active {
  color: white;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity));
    border-radius: 4px;
}
/* // ag grid theme // */
/* .ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  border-color: #babfc7;
  border-color: none;
}
.ag-theme-alpine .ag-header {
  background-color: #fff !important;
  color: #3e4676;
  font-size: 12px;
  border-bottom: 0px;
  line-height: 30px;
  height: 30px;
}

.ag-theme-alpine .ag-row {
  color: #3e4676;
  font-size: 0.85rem;
  font-weight: 400;
  vertical-align: middle;


}

.ag-theme-alpine .ag-cell {
  vertical-align: middle !important;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #F8F9FA !important;
  border-color: rgb(231, 231, 231) !important;
}
.ag-theme-alpine .ag-row-even {
  border-color: rgb(231, 231, 231) !important;
}
.ag-theme-alpine .ag-row {
  height: 40px !important;
} */

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

h1, h2, h3, p {
  margin: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.bg-cover {
  background-attachment: fixed;
  background-size: cover;
}

#features {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.shadow-lg:hover {
  transform: translateY(-10px);
}